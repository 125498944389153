<script lang="ts">
	import Ads from '$lib/components/Ads.svelte';
	import Aside from '$lib/components/Aside.svelte';
	import Nav from '$lib/components/Nav.svelte';
	import type { Sources } from '$lib/types';

	export let data: Sources;
</script>

<main>
	<nav
		class="bg-surface-100-800-token my-4 mr-0 p-2 sm:mb-0 sm:rounded-r-lg shadow-xl sm:border-r border-t border-b border-slate-500"
	>
		<Nav {data} />
	</nav>
	<article
		class="bg-surface-100-800-token lg:mt-4 sm:mr-0 sm:mt-4 sm:ml-4 lg:mx-4 pt-4 sm:rounded-l-lg lg:rounded-lg shadow-xl sm:border-x border-y border-slate-500"
	>
		<slot />
	</article>
	<aside
		class="bg-surface-100-800-token my-4 mr-0 sm:ml-4 sm:mb-0 lg:ml-0 py-4 p-2 sm:rounded-l-lg shadow-xl sm:border-l border-t border-b border-slate-500"
	>
		<Aside />
	</aside>
	<ad><Ads /></ad>
</main>

<style>
	main {
		display: grid;

		grid-template-areas:
			'nav'
			'content'
			'sidebar'
			'ad';
		color: rgb(var(--color-primary-100));
	}

	nav {
		grid-area: nav;
	}
	article {
		grid-area: content;
	}
	aside {
		grid-area: sidebar;
	}
	ad {
		grid-area: ad;
	}

	@media (width >= 640px) {
		main {
			grid-template-columns: 1fr 3fr;
			grid-template-areas:
				'nav     sidebar'
				'nav     content'
				'ad      ad';
		}
	}

	@media (width > 1025px) {
		main {
			grid-template-columns: 1fr 4fr 1fr;
			grid-template-areas:
				'nav   content   sidebar'
				'ad    ad        ad';
		}
	}
</style>
