<script lang="ts">
	import {
		numCharacters,
		numParagraphs,
		useHeaders,
		useBulletedLists,
		useNumberedLists,
		useBoldText,
		useItalicText,
		useLinks,
		renderAsHtml,
		renderAsMarkdown
	} from '$lib/stores';

	let selectedOutputOption = 'Text';
	let outputOptions = ['Text', 'Markdown', 'HTML'];

	const updateOutputOption = (option: string) => {
		switch (option) {
			case 'Markdown':
				$renderAsHtml = false;
				$renderAsMarkdown = true;
				break;
			case 'HTML':
				$renderAsHtml = true;
				$renderAsMarkdown = false;
				break;
			default:
				$renderAsHtml = false;
				$renderAsMarkdown = false;
		}
	};

	const toggleAllRenderOptions = (option: 'on' | 'off') => {
		if (option === 'on') {
			$useHeaders = true;
			$useBulletedLists = true;
			$useNumberedLists = true;
			$useBoldText = true;
			$useItalicText = true;
			$useLinks = true;
		} else if (option === 'off') {
			$useHeaders = false;
			$useBulletedLists = false;
			$useNumberedLists = false;
			$useBoldText = false;
			$useItalicText = false;
			$useLinks = false;
		}
	};
	$: allRenderOptionsSelected =
		$useHeaders &&
		$useBulletedLists &&
		$useNumberedLists &&
		$useBoldText &&
		$useItalicText &&
		$useLinks;
	$: noRenderOptionsSelected =
		!$useHeaders &&
		!$useBulletedLists &&
		!$useNumberedLists &&
		!$useBoldText &&
		!$useItalicText &&
		!$useLinks;
</script>

<div class="aside">
	<label for="numCharacters" class="label numCharacters">
		<span>Max # of characters</span>
		<input
			class="input rounded-lg"
			name="numCharacters"
			type="number"
			bind:value={$numCharacters}
			min="500"
		/>
	</label>

	<label for="numParagraphs" class="label numParagraphs">
		<span># of paragraphs</span>
		<input
			name="numParagraphs"
			class="input rounded-lg"
			type="number"
			bind:value={$numParagraphs}
			min="2"
		/>
	</label>

	<div class="list-items rendering">
		<h3>Rendering</h3>
		<div class="btn-group variant-soft-primary w-36 h-7">
			<button
				class="renderToggle w-1/2"
				class:variant-filled-primary={allRenderOptionsSelected}
				class:hover:variant-filled-primary={allRenderOptionsSelected}
				on:click={() => toggleAllRenderOptions('on')}>All</button
			>
			<button
				class="renderToggle w-1/2"
				class:variant-filled-primary={noRenderOptionsSelected}
				class:hover:variant-filled-primary={noRenderOptionsSelected}
				on:click={() => toggleAllRenderOptions('off')}>None</button
			>
		</div>
		<label>
			<input class="rounded" type="checkbox" bind:checked={$useHeaders} />
			Headers
		</label>

		<label>
			<input class="rounded" type="checkbox" bind:checked={$useBulletedLists} />
			Bulleted Lists
		</label>

		<label>
			<input class="rounded" type="checkbox" bind:checked={$useNumberedLists} />
			Numbered Lists
		</label>

		<label>
			<input class="rounded" type="checkbox" bind:checked={$useBoldText} />
			Bold Text
		</label>

		<label>
			<input class="rounded" type="checkbox" bind:checked={$useItalicText} />
			Italics Text
		</label>

		<label>
			<input class="rounded" type="checkbox" bind:checked={$useLinks} />
			Links
		</label>
	</div>
	<div class="list-items output">
		<h3>Output</h3>
		{#each outputOptions as option}
			<label>
				<input
					class="rounded"
					type="radio"
					bind:group={selectedOutputOption}
					name="scoops"
					value={option}
					on:change={() => updateOutputOption(option)}
				/>
				{option}
			</label>
		{/each}
	</div>
	<div class="ad"><!-- Aside Advertisement --></div>
</div>

<style lang="postcss">
	.aside {
		display: grid;
		gap: 1rem;
		grid-template-areas:
			'numCharacters'
			'numParagraphs'
			'rendering'
			'output'
			'ad';
	}
	.numCharacters {
		grid-area: numCharacters;
	}
	.numParagraphs {
		grid-area: numParagraphs;
	}
	.rendering {
		grid-area: rendering;
	}
	.output {
		grid-area: output;
	}
	.ad {
		grid-area: ad;
	}
	@media (width < 1024px) {
		.aside {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				'numCharacters     numParagraphs'
				'rendering     output'
				'ad     ad';
		}
	}

	.list-items {
		display: flex;
		flex-direction: column;
	}
	label > span,
	h3 {
		@apply text-sm md:text-base lg:text-lg font-bold;
	}
	label {
		@apply text-sm md:text-base;
	}
	.renderToggle {
		@apply transition-colors duration-500 active:scale-95;
	}
	input {
		@apply transition-all duration-500;
	}
</style>
