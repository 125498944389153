<script lang="ts">
	import type { Sources } from '$lib/types';
	export let data: Sources;

	import { selectedSources } from '$lib/stores';

	import { quintOut } from 'svelte/easing';
	import { crossfade } from 'svelte/transition';
	import { flip } from 'svelte/animate';

	const [send, receive] = crossfade({});

	const toggleSource = (key: string) => {
		if ($selectedSources.includes(key)) {
			//remove key from sources
			$selectedSources = $selectedSources.filter((s) => s !== key);
		} else {
			//add key to sources
			$selectedSources = [...$selectedSources, key];
		}
	};
</script>

<ul>
	{#each data.sources.filter((s) => $selectedSources.includes(s.key)) as source, idx (source.key)}
		<li
			in:receive={{ key: source.key }}
			out:send={{ key: source.key }}
			animate:flip
			class:active={$selectedSources.includes(source.key)}
		>
			<div
				role="button"
				tabindex={idx + 1}
				on:click={() => toggleSource(source.key)}
				on:keypress={() => toggleSource(source.key)}
			>
				<p>{source.label}</p>
				<img src={source.image} alt={source.label} sizes="16vw" srcset={source.srcset} />
			</div>
		</li>
	{/each}

	{#each data.sources.filter((s) => !$selectedSources.includes(s.key)) as source, idx (source.key)}
		<li
			in:receive={{ key: source.key }}
			out:send={{ key: source.key }}
			animate:flip
			class:active={$selectedSources.includes(source.key)}
		>
			<div
				role="button"
				tabindex={1 + $selectedSources.length + idx}
				on:click={() => toggleSource(source.key)}
				on:keypress={() => toggleSource(source.key)}
			>
				<p>{source.label}</p>
				<img src={source.image} alt={source.label} sizes="16vw" srcset={source.srcset} />
			</div>
		</li>
	{/each}
</ul>

<style>
	ul {
		padding: 0 0.5rem;
	}

	li {
		text-align: center;
		border-radius: var(--theme-rounded-base);
		overflow: hidden;
		margin: 1rem 0;
		padding: 0.5rem;
	}
	li > div {
		position: relative;
		list-style: none;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	li > div > p {
		position: absolute;
		z-index: 2;
		top: 50%;
		transform: translateY(-50%);
		font-weight: bold;
		font-size: clamp(1rem, 1.4vw, 2rem);
		max-width: 80%;
	}
	li > div > img {
		width: 95%;
		border-radius: var(--theme-rounded-base);
		filter: brightness(0.5);
	}
	li:hover {
		cursor: pointer;
		background-color: rgb(var(--color-primary-800));
	}
	li:hover > div > p {
		filter: drop-shadow(0 0 0.25rem black);
	}
	li:hover > div > img {
		filter: brightness(1);
	}
	.active {
		font-weight: bold;
		background-color: rgb(var(--color-primary-600));
	}
	@media (width <= 640px) {
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 8px;
			justify-content: center;
			max-height: 400px;
			overflow-y: scroll;
		}
		li {
			width: 150px;
			margin: 0;
		}
		li > div > img {
			max-height: 70px;
		}
	}
</style>
